import { mapActions, mapGetters } from 'vuex';
import isChildElement from '../../../../../helpers/isChildElement';
import { SEGMENT_WITH_SWITCH_PHONE } from '../../../../../enums/segments';

export default {
  data() {
    return {
      isTooltipOpened: false,
      showSwitchPhone: false,
    };
  },
  props: {
    id: Number,
  },
  async mounted() {
    await this.fetchProPhonesAssignment();
    if (
      SEGMENT_WITH_SWITCH_PHONE.includes(this.segment)
      || this.getProSellerPageData?.active
      || this.proPhonesAssignmentData?.active
    ) {
      this.showSwitchPhone = true;
    }
  },
  computed: {
    deleteReason() {
      return this.info.deleteReason || {};
    },
    links() {
      return {
        edit: `${this.langPrefix}/addauto/autoinfo/autoId/${this.id}/`,
      };
    },
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
      getRepublicationInfoById_: 'cabinet/advertisements/manage/getRepublicationInfoById',
      segment: 'cabinet/segment',
      getProSellerPageData: 'proAccount/getProSellerPageData',
      isActiveProSeller: 'proAccount/isActiveProSeller',
      proPhonesAssignmentData: 'proAccount/getProPhonesAssignmentData',
    }),

    info() {
      return this.info_(this.id);
    },
    republicationInfo() {
      return this.getRepublicationInfoById_(this.id) || {};
    },
    republicationStatus() {
      return Object.keys(this.republicationInfo).length ? Boolean(Number(this.republicationInfo.active)) : !this._result(this.info, 'aar.disable');
    },
  },
  methods: {
    ...mapActions({
      openPopup: 'cabinet/advertisements/popups/open',
      fetchProPhonesAssignment: 'proAccount/fetchProPhonesAssignment',
    }),
    toggleTooltip() {
      this.isTooltipOpened = !this.isTooltipOpened;
      if (this.isTooltipOpened && this.showSwitchPhone) this.changePhoneLogger({ request_type: 4 });
    },
    checkClose(event) {
      if (!isChildElement(this.$el, event.target)) {
        this.isTooltipOpened = false;
      }
    },
    changePhoneLogger(logData) {
      this._slonik({
        event_id: 1032, action_block: 2, proposal_id: this.id, ...logData,
      });
    },
  },
  watch: {
    isTooltipOpened(val) {
      if (val) {
        window.addEventListener('click', this.checkClose);
      } else {
        window.removeEventListener('click', this.checkClose);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('click', this.checkClose);
  },
  i18n: {
    messages: {
      uk: {
        Редактировать: 'Редагувати',
        Удалить: 'Видалити',
        'Сообщить о продаже': 'Повідомити про продаж',
        Перепубликация: 'Переопублікація',
        выключена: 'вимкнена',
        включена: 'увімкнена',
        'Настроить перепубликацию': 'Налаштувати перепублікацію',
        'Змінити телефон': 'Змінити телефон',
        'Завантажити QR-код': 'Завантажити QR-код',
        'Управління телефонами': 'Управління телефонами',
      },
      ru: {
        Редактировать: 'Редактировать',
        Удалить: 'Удалить',
        'Сообщить о продаже': 'Сообщить о продаже',
        Перепубликация: 'Перепубликация',
        выключена: 'выключена',
        включена: 'включена',
        'Настроить перепубликацию': 'Настроить перепубликацию',
        'Змінити телефон': 'Изменить телефон',
        'Завантажити QR-код': 'Загрузить QR-код',
        'Управління телефонами': 'Управление телефонами',
      },
    },
  },
};
